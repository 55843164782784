<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex class="mb-5">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>نشاط العميل </b>
            </h4>

            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row-wrap>
              <v-flex xs3 md3>
                <v-autocomplete
                  :items="customersActivity"
                  v-model="CustomerActivityId"
                  color="white"
                  item-text="name"
                  label="نشاط العميل"
                  item-value="id"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  :items="customers"
                  v-model="CustomerId"
                  color="white"
                  item-text="customerName"
                  label="اسم العميل"
                  item-value="customerNumber"
                  required
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="saveActivity()"
              style="background: #5867dd; color: white; margin-left: 5px"
              dark
            >
              <b>حفظ</b>
            </v-btn>
            <!--    <v-btn @click="$router.go(-1)" style="background:gray;" dark>
                    <b>رجوع</b>
                </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "customer-activity",

  data() {
    return {
      customersActivity: [],
      CustomerId: "",
      CustomerActivityId: "",
      customers: [],
    };
  },

  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");

    this.getCustomer();
    this.getListByActivity();
  },

  methods: {
    async getCustomer() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllCustomers"
      );
       response.data.responseData.forEach((el) => {
        if (
          el.customerNumber == 36 ||
          el.customerNumber == 40 ||
          el.customerNumber == 95 ||
          el.customerNumber == 153 ||
          el.customerNumber == 3663 ||
          el.customerNumber == 6081 ||
          el.customerNumber == 6106 ||
          el.customerNumber == 6206 ||
          el.customerNumber == 9498 ||
          el.customerNumber == 6331 ||
          el.customerNumber == 9496 ||
          el.customerNumber == 6454 ||
          el.customerNumber == 6105 ||
          el.customerNumber == 91 ||
          el.customerNumber == 6443
        )
          this.customers.push(el);
      });
    },
    saveActivity() {
      let data = {
        CustomerId: this.CustomerId,
        ActivityId: this.CustomerActivityId,
      };
      this.ApiService.post("Customer/SetActivities", data)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
        });
    },
    async getListByActivity() {
      const response = await this.ApiService.get("Activity/List");

      response.data.responseData.forEach((el) => {
        this.customersActivity.push({ name: el.name, id: el.id, Target: "" });
      });
    },
  },
};
</script>
